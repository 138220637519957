* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main {
  display: flex;
  height: 100vh;
  flex-direction: row;
  background-color: whitesmoke;
}
