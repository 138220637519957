.add-room-modal {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
}

.add-room-modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  font-size: 24px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
  justify-content: space-between;
}

.add-room-modal-title {
  display: flex;
  align-items: center;
  gap: 20px;
}

.add-room-modal-close {
  cursor: pointer;
  display: flex;
  padding: 5px;
  transition: 0.2s;
  border-radius: 50%;
}

.add-room-modal-close:hover {
  background-color: whitesmoke;
  border-radius: 50%;
}

.add-room-modal-header svg {
  color: #4957fa;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
