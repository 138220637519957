.rooms {
  margin: 20px;
  background-color: white;
  flex: 40%;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.room-add-new {
  display: flex;
  gap: 30px;
  margin-top: auto;
  font-size: 20px;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  color: rgb(156, 156, 156);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 14px;
  background-color: whitesmoke;
  border-radius: 30px;
}
