.bet-list-header {
  color: #4957fa;
  gap: 30px;
  display: flex;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin: 10px 20px 20px 20px;
}

.bet-list-header-tries {
  /* text-decoration: underline;
  text-underline-offset: 2px; */
}

.bet-list-header-tries-wrapper {
  margin-left: auto;
}
