.bet-list {
  display: flex;
  flex-direction: column;
  color: #4957fa;
  font-size: 18px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  justify-content: center;
  margin-bottom: 20px;
}

.bet-list-item {
  display: flex;
  flex-direction: row;
  border-radius: 40px;
  margin-bottom: 4px;
  padding: 16px 20px;
  gap: 20px;
  background-color: #4958fa34;
  align-items: center;
}

.bet-list-item:not(.inactive) {
  border: 1px solid;
}

.bet-list-item-user {
  flex: 10%;
  display: flex;
  margin-left: 10px;
  align-items: center;
}

.bet-list-item-lower-boundary {
  flex: 10%;
  display: flex;
  align-items: center;
}

.bet-list-item-higher-boundary {
  flex: 10%;
  display: flex;
  align-items: center;
}

.bet-list-item-amount {
  flex: 10%;
  display: flex;
  align-items: center;
}

.bet-list-item span {
  margin-left: 20px;
}

.bet-list-item-created-at {
  display: flex;
  flex: 10%;
}

.inactive {
  color: gray;
  background-color: whitesmoke;
  border-radius: 40px;
}
