.modal-overlay {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.1s height 0s;
  overflow: auto;
}

.modal {
  background-color: white;
  min-width: 22%;
  max-width: 38%;
  margin-top: 6%;
  border-radius: 19px;
  padding: 10px 10px 10px 10px;
}

.modal-header-subtext {
  font-size: 14px;
  color: gray;
  margin-top: -4px;
}

.modal-body {
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 25px 20px 25px;
  margin-top: 10px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 18px 20px 15px 30px;
}

.modal-header-title {
  align-items: center;
  display: flex;
  color: #4957fa;
}

.modal-header-close {
  color: #4957fa;
  cursor: pointer;
  display: flex;
  padding: 5px;
  place-self: center;
  border-radius: 50%;
}

.modal-header-close:hover {
  background-color: #eee;
  color: #4957fa;
}

.modal-header-divider {
  margin: 0px 24px;
  margin-bottom: 20px;
  background: rgb(238, 238, 238);
  border: 2px solid rgb(238, 238, 238);
  border-radius: 4px;
}

.modal-header-text {
  margin-left: 18px;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  color: #2c3e50;
  flex-direction: column;
}
