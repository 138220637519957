.bets {
  display: flex;
  flex: 40%;
  margin: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  flex-direction: column;
}

.bets-loading-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
}

.no-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #4957fa;
  font-size: 30px;
  font-family: 'Segoe UI';
  font-weight: 600;
  margin: auto;
}

.winner {
  color: #4957fa !important;
  background-color: #4958fa34 !important;
  padding-left: 28px !important;
  cursor: default !important;
}

.no-content span {
  color: black;
  margin-top: 20px;
  margin-bottom: 20px;
}
