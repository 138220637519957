.room-list-header {
  display: flex;
  flex-direction: row;
  color: rgb(0, 0, 0);
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  align-items: center;
  color: #4972fa;
  padding: 0 40px;
  margin: 10px 0 20px 0;
}

.header-title {
  color: black;
  margin-left: 20px;
}

.header-points {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 40px;
}

.header-points span {
  color: black;
  text-transform: none;
}

.header-username-container {
  color: #4957fa;
  text-transform: none;
  display: flex;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* margin-left: auto; */
}

.header-username {
  color: black;
  margin-left: 14px;
}

.room-list-header-item {
  display: flex;
}

.right {
  justify-content: end;
}

.center {
  justify-content: center;
}

.left {
  justify-content: start;
}
