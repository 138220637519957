.button {
  background-color: #ffffff;
  color: #2e3e50;
  outline: none;
  border: none;
  border-radius: 8px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 6px 24px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: 550;
  transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  font-size: 16px;
}

.button-primary {
  background-color: #4957fa !important;
  color: white !important;
}

.button:hover {
  background-color: #eee;
}
