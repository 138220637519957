.room-list {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.room-list-item {
  display: flex;
  flex-direction: row;
  padding: 10px 24px;
  margin-bottom: 10px;
  border-radius: 26px;
  cursor: pointer;
  align-items: center;
  transition: 0.2s;
}

.room-list-item-creator {
  display: flex;
  flex: 21%;
}

.room-list-item-live-indicator {
  display: flex;
  flex: 18%;
  color: #4957fa;
}

.room-list-item-active {
  color: #4957fa;
  display: flex;
  align-items: center;
  gap: 26px;
  flex: 10%;
  justify-content: flex-end;
}

.room-list-item-icon {
  color: #4957fa;
  margin-right: 18px;
}

.room-list-item-description {
  flex: 21%;
}

.room-list-item-deaths-count {
  margin-right: 20px;
  color: black;
}

.room-list-item-deaths {
  display: flex;
  flex: 14%;
  color: #4957fa;
  align-items: center;
}
.room-list-item:hover {
  background-color: whitesmoke;
}

.activity-text {
  font-weight: 600;
}
